.project-dialog {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  backdrop-filter: blur(12px);
  z-index: 99999;
}

.project-dialog-ctn {
  padding: 20px;
  position: relative;
  width: 90%;
  height: 50%;
  border-radius: 26px;
  max-width: 500px;
  background: #1F1F1F;
  border: 1px solid #2b2b2b;
}

.project-dialog-close {
  right: 20px;
  padding: 5px;
  position: absolute;
  border-radius: 50%;
  background: #D6DD3D;
}
.project-dialog-title {
  color: #fff;
  font-size: 20px;
}
.project-dialog-ctninput {
  margin: 30px 0;
}
.project-dialog-label{
  font-size: 16px;
  color: #fff;
  margin-bottom: 16px;
}
.project-dialog-description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 60px);
}

.project-dialog-description > div > button:disabled {
  background: #989898;
  color: #000;
}