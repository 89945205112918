.notification-container {
  display: flex;
  height: auto;
  align-items: normal;

  align-items: flex-start;
  flex-direction: column;
}
.notification-container a {
  margin-bottom: 20px;
}

.notify-left {
  margin-left: 10px;
  font-weight: 300;
  font-size: 16px;
}

.notify-people {
  display: flex;
  align-items: center;
}

.notify-btn-content {
  margin-left: 0;
  margin-top: 50px;
}

.button-unfollow {
  color: #cad144;
  cursor: pointer;
  background: transparent;
  border: none;
  font-weight: 600;
  font-size: 16px;
  min-width: 160px;
  text-transform: uppercase;
}
.button-unfollow:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.notify-image {
  width: 70px !important;
  height: 70px !important;
  border: solid 3px #d4e245;
}

.search-results > a > div.names-images {
  font-size: 16px;
  font-weight: 300;
}

.notify-seenapse {
  font-size: 16px;
  text-transform: capitalize;
}

.notify-loader {
  width: 30px;
  height: 30px;
}

@media (min-width: 767px) {
  .notification-container {
    align-items: normal;
    flex-direction: row;
  }
  .notify-btn-content {
    margin-left: 40px;
    height: 245px;
    margin-top: 0;
    display: flex;
    align-items: center;
  }
}
