.subscription {
  color: #fff;

}
.subscription-title > h2 {
  font-size: 38px;
  line-height: 46px;
  font-weight: 300;
  margin-bottom: 12px;
  font-family: HelveticaNeue-Light;
}

.subscription-title > p {
  font-size: 24px;
  line-height: 29px;
  font-weight: 300;
  font-family: HelveticaNeue-Light;
}
.subscription-row {
  margin-top: 32px;
}
.subscription-col {
  width: 255px;
  text-align: center;
}
.subscription-col h2 {
  font-size: 20px;
  line-height: 30px;
  font-family: HelveticaNeue-Bold;
  text-transform: uppercase;
  text-align: center;
  margin-top: 10px;
}
.subscription-col .casual, .personal, .small, .big-teams {
  width: 100%;
}

.subscription-ctn  {
  margin-bottom: 40px;
  margin-top: 40px;
}
.subscription-ctn p {
  font-size: 18px;
  line-height: 30px;
  text-align: center;
}
.text-cursive {
  font-style: italic;
}
.row-container {
  position: absolute;
  margin-top: -30px;
  margin-right: 0 !important;
}

.d-none {
  display: none;
}

.text {
  color: #fff;
  font-weight: 100;
  font-size: 36px;
  letter-spacing: 1px;
}

.margin-boottom-lg {
  margin-bottom: 60px;
}

.margin-top {
  margin-top: -235px;
}

.padding-top {
  padding-top: 50px;
}
/*
.about {
  font-size: 14px;
  position: absolute;
  margin: 0 auto;
} */

.margin-bottom {
  margin-bottom: 50px;
}

.contenedor {
  top: 48px;
  position: relative;
}

.paragraph {
  font-size: 20px;
  margin: 0;
  padding: 0;
  border: 0;
  line-height: 100%;
  vertical-align: baseline;
  text-shadow: 0 0 black;
  color: white;
  letter-spacing: 1px;
  font-weight: 180;
}

.logo-find {
  width: 100%;
  height: 100%;
  position: absolute;
}

.padding-left {
  padding-left: 15px;
}

.inline-block {
  display: inline-block;
}

.padding-lg-left {
  padding-left: 5%;
}
.private-beta {
  background: #9d76ba;
  height: 20px;
  padding: 0 8px;
  width: 110px;
  text-align: center;
  line-height: 20px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 700;
}

@media only screen and (max-width: 480px) and (min-width: 380px) {
  .title-sm {
    font-size: 28px;
  }
  .paragraph-sm {
    font-size: 16px;
  }
  .footer-sm {
    visibility: hidden;
  }
  .content-avatar {
    padding-left: 0;
    position: relative;
    right: 0;
  }
  .margin-bottom-incorporate-sm {
    margin-bottom: 20px;
  }
  .padding-share-sm {
    padding-left: 15px !important;
  }
}

@media (max-width: 420px) {
  .hidden-text {
    display: none;
  }
  .margin-bottom-sm {
    margin-bottom: 0;
  }
  .bottom-margin-sm {
    margin-bottom: 30px;
  }
  .margin-bottom-input {
    margin-bottom: 15px;
  }
  .margin-bloque-sm {
    margin-bottom: 30px;
  }
  .padding-share-sm {
    padding-left: 15px !important;
  }
  .top-sm {
    top: 0;
  }
  .margin-bottom-incorporate-sm {
    margin-bottom: 20px;
  }
  .content-avatar {
    padding-left: 0;
    position: relative;
    right: 0;
  }
}

@media only screen and (max-width: 379px) and (min-width: 300px) {
  img[alt="Logo-Seenapse"] {
    width: 100%;
  }
  .content-avatar {
    padding-left: 0;
    position: relative;
    right: 0;
  }
  .results-seenapses {
    height: auto;
  }
  .message-results {
    font-size: 16px !important;
  }
  .message-results:last-child {
    font-size: 14px !important;
  }
  .footer-sm {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .contenedor {
    top: 15px !important;
  }
  .padding-left-md {
    padding-left: 0 !important;
  }
  .rigth-md {
    text-align: right;
  }
  .hidden-text {
    display: none;
  }
  .margin-bloque-sm {
    margin-bottom: 30px;
  }
  .margin-incorporate-sm {
    margin-top: 30px;
  }
  .margin-bottom-incorporate-sm {
    margin-bottom: 20px;
  }
  .footer-sm {
    visibility: hidden;
  }
  .content-avatar {
    padding-left: 0 !important;
    position: relative !important;
    right: 0 !important;
  }
  .results-seenapses {
    height: auto;
  }
  .message-results {
    font-size: 20px;
  }
  .message-results:last-child {
    font-size: 16px;
  }
}
