.dialog {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 100000;
}

.dialog.landscape {
  overflow-y: hidden;
  overflow-x: auto;
  top: 0;
}

.dialog .background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
