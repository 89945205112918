figure {
  margin: 0;
  padding: 0;
}

.name-user {
  font-size: 20px;
}

.styles_modal__gNwvD1 {
  background-color: #212020;
  position: absolute;
  top: 0;
  width: 100%;
  max-width: 380px;
  right: 0;
  border-bottom-left-radius: 40px;
  border-top-left-radius: 40px;
  border-bottom-right-radius: 40px;
  border-top-right-radius: 40px;
}

.transitionExitActive {
  opacity: 0;
  transition: opacity 0ms;
  background-color: transparent;
}

.group-info {
  display: block;
  margin-top: 1em;
}

.about-mobile {
  display: inline-flex;
  margin: 12.5px 0 12.5px 0;
}

.about-mobile:last-child {
  font-size: 21px;
  padding: 0;
  margin: 12.5px 0 12.5px 0;
}

.notifications-mobile {
  width: 30px;
  height: 30px;
  background-color: #91cae5;
  color: black;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  position: absolute;
  right: 22px;
  padding-top: 3px;
  font-weight: 700;
  margin-top: 11px;
}
