.climate-footer {
  width: 100%;
  padding: 20px;
  max-width: 890px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.climate-footer-text {
  position: relative;
  color: #fff;
  font-size: 10px;
  font-weight: 300;
  font-style: normal;
  padding: 0 8px;
  margin: 0;
}
.footer-climate-nasa {
  display: flex;
  align-items: center;
}
.climate-footer-divider::before {
  content: '';
  width: 1px;
  height: 80%;
  position: absolute;
  right: 0;
  background: #fff;
}

@media screen and (min-width: 767px) {
  .climate-footer{
    padding: 20px 38px;
  }
  .climate-footer-text {
    font-size: 12px;
  }
}