.results-seenapses {
    /* background-color: black; */
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    height: auto;
    width: 100%;
    padding-left: 28px;
    margin: 20px 0;
    transition: filter 300ms ease-out;
  }
  
  .link-underline:hover {
    text-decoration: none;
  }
  
  .about-prueba {
    color: white;
    font-size: 40px;
    text-align: center;
    position: absolute;
    top: 500px;
  }
  
  .container-search {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    position: relative;
    transition: filter 300ms ease-out;
    overflow-y: scroll;
    max-height: 90vh;
  }
  
  .message-results {
    font-size: 22px;
    letter-spacing: 1px;
    color: white;
    font-weight: 150;
    margin: 0;
  }
  
  .message-results:last-child {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  /* .search-results {
    background-image: url('../img/base_search_results.png');
    height: auto;
    width: 168px;
    margin: 50px 25px;
  } */
  
  .format-images-results {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: absolute;
    border: solid 3px #d4e245;
    display: inline-block;
    box-sizing: border-box;
    background-size: cover;
    cursor: pointer;
    background-repeat: no-repeat;
    border-image: initial;
    background-position: center center;
  }
  
  .result-parent {
    position: absolute;
  }
  
  .result-children {
    position: relative;
    left: 68px;
  }
  
  .by-who {
    text-align: center;
  }
  
  .by-who p {
    font-size: 0.5rem;
    font-weight: 400;
    letter-spacing: 1px;
    color: #a09f9f;
    text-transform: uppercase;
  }
  
  .who {
    border-radius: 50px;
    border: 3px solid #2b2b2b;
    width: 50px;
    height: 50px;
    text-align: center;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  
  @media only screen and (max-width: 514px) and (min-width: 379px) {
    .results-seenapses {
      height: auto;
    }
  
    .message-results {
      font-size: 18px;
    }
  
    .message-results:last-child {
      font-size: 16px;
    }
  }
  
  .search-results-people {
    height: 21px;
    width: 201px;
    margin: 71px 0;
  }
  
  .picUs {
    width: 97px;
    height: 97px;
    margin: auto;
    display: block;
    -webkit-border-radius: 97px;
    border-radius: 97px;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    background-color: #2b2b2b;
  }
  