.results-seenapses-account {
  background-color: black;
  justify-content: flex-end;
  flex-direction: column;
  z-index: 1020;
  height: 80px;
  width: 100%;
  padding-left: 28px;
  margin-bottom: 20px 0;
}

.container-search {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  position: relative;
}

.message-results {
  font-size: 22px;
  letter-spacing: 1px;
  color: white;
  font-weight: 150;
  margin: 0;
}

.message-results:last-child {
  font-size: 18px;
  margin-bottom: 20px;
}

.search-results {
  background-image: url('../../assests/img/base_search_results.png');
  background-repeat: no-repeat;
  height: auto;
  width: 168px;
  margin: 50px 25px;
}

.format-images-results {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: absolute;
  border: solid 3px #d4e245;
  display: inline-block;
  box-sizing: border-box;
  background-size: cover;
  cursor: pointer;
  background-repeat: no-repeat;
  border-image: initial;
  background-position: center center;
}

.result-parent {
  position: absolute;
}

.result-children {
  position: relative;
  left: 68px;
}

.names-images {
  margin: 10px 0 10px;
  color: white;
  font-size: 0.75em;
  text-align: center;
}

#header-info .name {
  color: #777;
  font-size: 1.3rem;
  font-weight: 400;
  margin: -46px 0 0 61px;
}

#header-info .cat {
  color: #777;
  margin: 0 0 15px 61px;
}

.bio-font-size {
  font-size: 0.9rem;
}

@media only screen and (max-width: 514px) and (min-width: 379px) {
  .results-seenapses {
    height: auto;
  }

  .message-results {
    font-size: 18px;
  }

  .message-results:last-child {
    font-size: 16px;
  }
}

.search-results-people {
  height: 21px;
  width: 201px;
  margin: 71px 0;
}

.picUs {
  width: 97px;
  height: 97px;
  margin: auto;
  display: block;
  border-radius: 97px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #2b2b2b;
}

.theme-blue {
  color: #91cae5 !important;
}
.theme-yellow {
  color: #d4e245 !important;
}

#header-spacer {
  padding: 89px 25px 0 25px;
}

#section-options {
  background: inherit;
  overflow: hidden;
  position: fixed;
  user-select: none;
  width: 100%;
  height: 91px;
  top: 78px;
  left: 0;
  padding: 25px;
  color: #717171;
  font-weight: 300;
  opacity: 0;
  z-index: 1;
}

@media screen and (max-width: 450px) {
  #section-options {
    top: 92px;
    height: 62px;
  }
}

#section-options .title {
  color: inherit;
  letter-spacing: 1px;
  position: relative;
}

#section-options .title h2 {
  margin: 0 5px;
  display: inline-block;
}

#section-options .title h2 a {
  color: inherit;
  display: inline-block;
}

#section-options .title h2 a:hover {
  cursor: pointer !important;
}

#header-info {
  background-color: black;
  color: #fff;
  width: 100%;
  padding: 25px;
  z-index: 1;
  display: flex;
}

#header-info .info-container {
  width: 50%;
}

#header-info .button-prefs {
  background: #4e636d;

  width: 150px;
  font-size: 11px;
  height: 22px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-left: 61px;
}
#header-info .button-prefs a {
  padding: 10px;
  color: blue;
  text-transform: uppercase;
  text-decoration: none;
}

#header-info .title {
  margin-right: 7%;
  margin-top: 3px;
  border-radius: 81px;
  width: 111px;
  height: 16px;
  padding: 3px;
  text-align: center;
  background: #4e636d;
  z-index: 1;
  cursor: pointer;
  margin-left: 62px;
}

#header-info .title h2 {
  font-size: 11px;
  font-family: HelveticaNeue;
  line-height: 1;
}

#header-info .titleLeft {
  float: left;
  margin-right: 20px;
}

#header-info .titleLeft h2 {
  line-height: 1;
}

#header-info .info {
  font-size: 0.9em;
  width: 100%;
}

#header-info .user-avatar {
  width: 55px;
  height: 55px;
  border-radius: 55px;
  background: #262626;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  max-height: 55px;
}

#header-info .user-avatar img {
  border-radius: 55px;
  padding: 4px;
  max-height: 55px;
  height: 55px;
}

#header-info .location {
  color: #777;
  display: inline-block;
  vertical-align: middle;
  margin-left: 61px;
  margin-top: -8px;
}

#header-info .bio {
  padding-left: 20px;
  border-left: 1px solid #353535;
  color: #777;
  font-size: 0.9em;
}

#circle-menu {
  user-select: none;
  width: 100%;
  padding: 25px;
  color: #fff;
  font-weight: 300;
  position: fixed;
  z-index: 1;
  margin-top: 190px;
}

#circle-menu div {
  display: inline-block;
  margin: 0 25px 0 0;
  cursor: pointer;
  height: 41px;
}

#circle-menu div .hits {
  background-color: #2b2b2b;
  color: #717171;
  border-radius: 50px;
  width: 41px;
  height: 41px;
  position: relative;
  margin: 0;
  z-index: 1;
}

#circle-menu div .hits .circle {
  background-color: #2b2b2b;
  color: #717171;
  border-radius: 50px;
  width: 41px;
  height: 41px;
  position: absolute;
  z-index: 0;
  margin: 0;
}

#circle-menu div .hits p {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 1;
}

#circle-menu span {
  top: -13px;
  position: relative;
  margin: 0 0 0 5px;
  font-size: 22px;
  color: #717171;
  font-weight: 100;
  letter-spacing: 1px;
}

#circle-menu div .hits p span {
  position: relative;
  text-align: inherit;
  top: 7px;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  margin: 0;
  color: inherit;
}

@media screen and (max-width: 600px) {
  #circle-menu span {
    font-size: 16px;
    top: -16px;
  }
}

#circle-menu-notif {
  user-select: none;
  width: 570px;
  padding: 25px;
  color: #fff;
  font-weight: 300;
  opacity: 1;
  float: right;
  margin-top: -29px;
}

#circle-menu-notif div {
  display: inline-block;
  margin: 0 25px 0 0;
  cursor: pointer;
  height: 41px;
}

#circle-menu-notif div .hits {
  background-color: #2b2b2b;
  color: #717171;
  border-radius: 50px;
  width: 41px;
  height: 41px;
  position: relative;
  margin: 0;
  z-index: 1;
}

#circle-menu-notif div .hits .circle {
  background-color: #2b2b2b;
  color: #717171;
  border-radius: 50px;
  width: 41px;
  height: 41px;
  position: absolute;
  z-index: 0;
  margin: 0;
}

#circle-menu-notif div .hits p {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 1;
}

#circle-menu-notif div span {
  top: -13px;
  position: relative;
  margin: 0 0 0 5px;
  font-size: 22px;
  color: #717171;
  font-weight: 100;
  letter-spacing: 1px;
}

#circle-menu-notif div .hits p span {
  position: relative;
  text-align: inherit;
  top: 7px;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  margin: 0;
  color: inherit;
}

@media screen and (max-width: 600px) {
  #circle-menu-notif div span {
    font-size: 16px;
    top: -16px;
  }
}

.title-preferences {
  font-weight: 100; 
  font-size: 36px; 
  letter-spacing: 1px;
  color: white;
  margin-left: 27px;
  margin-top: 17px;
  max-width: 1440px;
  text-align: center;
  margin: 0 auto;
}

.account-link-button {
  background: #D6DD3D;
  border: none;
  min-height: 60px;
  border-radius: 20px;
  color: black;
  width: 150px;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: HelveticaNeue-bold;
}
.account-link-button:hover {
  color: black;
  text-decoration: none;
}