#profile-preferences {
  color: #fff;
  padding: 25px;
  max-width: 1440px;
  margin-top: 30px;
}
#profile-preferences form {
  clear: both;
  display: flex;
  flex-direction: column;
  width: 100%;
}
#profile-preferences .container-profile {
  display: flex;
  flex-direction: column;
  width: 100%;
}
#profile-preferences form:before,
#profile-preferences form:after {
  content: ' ';
  display: table;
}
#profile-preferences form:after {
  clear: both;
}
#profile-preferences label,
#profile-preferences button {
  font-size: 10px;
  text-transform: uppercase;
}
#profile-preferences label {
  color: #525b18;
  display: block;
}
#profile-preferences button {
  font-weight: bold;
}
#profile-preferences fieldset {
  outline: 0;
  border: 0;
}
#profile-preferences input[type='text'],
#profile-preferences input[type='password'],
#profile-preferences input[type='email'],
#profile-preferences textarea,
#profile-preferences select {
  background-color: #2b2b2b;
  color: #fff;
  padding: 2px 16px;
  border: 1px solid #717171;
  display: block;
  width: 100%;
  font-size: 14px;
  height: 38px;
  border-radius: 10px;
}
#profile-preferences input:disabled,
#profile-preferences textarea:disabled,
#profile-preferences select:disabled {
  background: #424242 !important;
  color: #949292;
  cursor: not-allowed;
}
#profile-preferences input[type='text'].error,
#profile-preferences input[type='password'].error,
#profile-preferences input[type='email'].error,
#profile-preferences textarea.error,
#profile-preferences select.error {
  border-color: #91cae5 !important;
}
#profile-preferences select {
  -webkit-appearance: none;
  border-radius: 0;
  border-color: #000;
  cursor: pointer;
  border: 1px solid #717171;
  border-radius: 10px;
}
#profile-preferences input[type='checkbox'] {
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: url('../../assests/img/square-check.gif');
}
#profile-preferences input[type='checkbox']:checked {
  background: url('../../assests/img/square-check-selected.gif');
}
#profile-preferences label {
  margin-bottom: 5px;
}
#profile-preferences button {
  background: transparent;
  border: none;
  padding: 3px;
  color: #717171;
  cursor: pointer;
}
#profile-preferences textarea {
  resize: none;
  height: 180px;
}
#profile-preferences .info {
  clear: both;
  float: left;
  clear: none;
  text-align: inherit;
  width: 22.75%;
  margin-left: 0%;
  margin-right: 3%;
}
#profile-preferences .info:before,
#profile-preferences .info:after {
  content: ' ';
  display: table;
}
#profile-preferences .info:after {
  clear: both;
}
#profile-preferences .info:last-child {
  margin-right: 0%;
}
#profile-preferences .info > div {
  margin-bottom: 15px;
}
#profile-preferences .additional {
  clear: both;
  float: left;
  clear: none;
  text-align: inherit;
  width: 22.75%;
  margin-left: 0%;
  margin-right: 3%;
}
#profile-preferences .additional:before,
#profile-preferences .additional:after {
  content: ' ';
  display: table;
}
#profile-preferences .additional:after {
  clear: both;
}
#profile-preferences .additional:last-child {
  margin-right: 0%;
}
#profile-preferences .profile-pic {
  clear: both;
}
#profile-preferences .profile-pic:before,
#profile-preferences .profile-pic:after {
  content: ' ';
  display: table;
}
#profile-preferences .profile-pic:after {
  clear: both;
}
#profile-preferences .profile-pic .pic,
#profile-preferences .profile-pic .options {
  float: left;
}
#profile-preferences .profile-pic .pic img {
  width: 80px;
  height: 80px;
  vertical-align: bottom;
  border-radius: 100px;
}
#profile-preferences .profile-pic .options {
  padding-left: 10px;
}
#profile-preferences .profile-pic .options button {
  display: block;
  margin-bottom: 5px;
  text-align: left;
}
#profile-preferences .mail .action {
  text-align: right;
}
#profile-preferences .selectors > div {
  margin-bottom: 15px;
}
#profile-preferences .select-container {
  position: relative;
}
#profile-preferences .select-container .arrow {
  position: absolute;
  height: 38px;
  width: 25px;
  background: url('../../assests/img/dropdown-arrow.png');
  display: block;
  right: 10px;
  top: 26px;
  pointer-events: none;
  overflow: hidden;
  background-repeat: no-repeat;
}
#profile-preferences .action {
  text-align: right;
}
#profile-preferences table {
  font-size: 12px;
  width: 100%;
  color: #717171;
  border: 0;
  border-spacing: 2px;
  margin-bottom: 10px;
}
#profile-preferences thead {
  background-color: #2b2b2b;
}
#profile-preferences tbody tr {
  border: 1px solid #2b2b2b;
}
#profile-preferences th {
  padding: 10px;
  font-weight: normal;
  border: 0;
  outline: 0;
}
#profile-preferences th.col-1 {
  background: url('../../assests/img/th-pattern.gif');
}
#profile-preferences th.col-2 {
  background-color: #262626;
}
#profile-preferences th.col-3 {
  background-color: #333;
}
#profile-preferences th.col-4 {
  background-color: #404040;
}
#profile-preferences td {
  padding: 10px 5px;
  border-bottom: 1px solid #242424;
}
#profile-preferences td.option-cell {
  text-align: center;
  border-bottom: 1px dotted #242424;
}
#profile-preferences {
  clear: both;
}
#profile-preferences:before,
#profile-preferences:after {
  content: ' ';
  display: table;
}
#profile-preferences:after {
  clear: both;
}
#profile-preferences .col1,
#profile-preferences .col2,
#profile-preferences .col3 {
  clear: both;
  float: left;
  clear: none;
  text-align: inherit;
  width: 31.33333333333333%;
  margin-left: 0%;
  margin-right: 3%;
}
#profile-preferences .col1:before,
#profile-preferences .col2:before,
#profile-preferences .col3:before,
#profile-preferences .col1:after,
#profile-preferences .col2:after,
#profile-preferences .col3:after {
  content: ' ';
  display: table;
}
#profile-preferences .col1:after,
#profile-preferences .col2:after,
#profile-preferences .col3:after {
  clear: both;
}
#profile-preferences .col1:last-child,
#profile-preferences .col2:last-child,
#profile-preferences .col3:last-child {
  margin-right: 0%;
}
#profile-preferences .col1 > div,
#profile-preferences .col2 > div,
#profile-preferences .col3 > div {
  margin-bottom: 15px;
}
#profile-preferences.login-form label {
  color: #efefef;
  float: left;
}

#profile-preferences.login-form label.required:after {
  content: '*';
  font-size: 25px;
  vertical-align: bottom;
  line-height: 0;
  color: #91cae5;
  margin-left: 5px;
}

.textarea-update {
  height: 110px !important;
}
.file {
  position: relative;
  display: inline-block;
  cursor: pointer;
  height: 2.5rem;
  margin-bottom: 0;
}
.file input {
  height: 100%;
}
.file-title {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: 2.5rem;
  padding: 0.5rem 0;
  text-align: left;
  line-height: 1.5;
  color: #d4e245;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #000;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.file-title:after {
  content: '';
}
.pic-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.action-form {
  margin-top: 85px;
  clear: both;
}
.action-form:before,
.action-form:after {
  content: ' ';
  display: table;
}
.action-form:after {
  clear: both;
}
.action-form .left,
.action-form .right {
  clear: both;
  float: left;
  clear: none;
  text-align: inherit;
  width: 48.5%;
  margin-left: 0%;
  margin-right: 3%;
}
.action-form .left:before,
.action-form .right:before,
.action-form .left:after,
.action-form .right:after {
  content: ' ';
  display: table;
}
.action-form .left:after,
.action-form .right:after {
  clear: both;
}
.action-form .left:last-child,
.action-form .right:last-child {
  margin-right: 0%;
}
.action-form .left {
  font-size: 10px;
  text-transform: uppercase;
  color: #3a505d;
}
.action-form .left .asterisk {
  font-size: 25px;
  vertical-align: bottom;
  line-height: 0;
  color: #91cae5;
  margin-left: 5px;
}
.action-form .right {
  text-align: right;
}
.action-form .right button {
  margin-left: 20px;
}
.s {
  color: #91cae5;
  font-size: 0;
  display: none;
}
#cncl:hover {
  color: #fff;
}
#myFile {
  position: fixed;
  overflow: hidden;
  float: left;
  clear: left;
  visibility: hidden;
}
#labelUpload {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  background: transparent;
  border: none;
  padding: 3px;
  color: #717171;
  display: block;
  margin-bottom: 5px;
  text-align: left;
}

.checkbox {
  position: absolute;
  margin: 0 !important;
  padding: 0 !important;
  opacity: 0;
  height: 0 !important;
  width: 0 !important;
  pointer-events: none;
}
.label-prefs {
  font-size: 14px !important;
  text-transform: none!important;
}
.remove-image {
  font-size: 14px !important;
  font-weight: 300 !important;
  margin-left: 8px;
  text-align: left;
  margin-top: -10px;
}
@media (min-width: 768px) {
  .profile-avatar {
    position: absolute;
  }
}
