.inspired {
  width: 80%;
  max-width: 350px;
  min-height: 610px;
  border-radius: 50px;
  background: white;
  padding: 20px 24px;
  position: relative;
  margin-left: auto;
  margin-right: 40px;
  margin-top: 100px;
}
.inspired-ideas {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 350px;
}
.inspired-images {
  position: absolute;
  left: -30px;
  top: -20px;
}
.inspired-images .thumbnail {
  margin-bottom: -30px;
}
.inspired-images .thumbnail_link {
  width: 54px;
  height: 54px;
  border: #917abd 0.4em solid;
  position: relative;
}

.inspired > form {
  text-align: start;
  margin-top: 20px;
}
.inspired-ctntitle {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}
.inspired-subtitle {
  color: black;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  margin-left: 5px;
  margin-bottom: 0;
  align-self: center !important;
}
.inspired-title {
  color: black;
  font-weight: 300;
  font-size: 20px;
}
.inspired-ideasSubtitle {
  color: black;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 15px;
  align-self: center !important;
}
.inspired-ideasTitle {
  color: black;
  font-weight: 300;
  font-size: 20px;
}

.inspired-label {
  color: black;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  margin-left: 10px;
}
.inspired-ctninput {
  margin-bottom: 4px;
}
.inspired-input {
  height: 40px;
  width: 100%;
  border-radius: 20px;
  background: #efeff4;
  border: none;
  padding: 0 12px;
}
.inspired-button {
  background: #917abd;
  border: none;
  height: 40px;
  border-radius: 20px;
  color: #fff;
  min-width: 150px;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  margin-top: 20px;
}
.inspired-button:disabled {
  opacity: 0.8;
  pointer-events: none;
  cursor: not-allowed;
}

.inpired-privacytext {
  color: black;
  font-weight: 300;
  font-size: 14px;
  margin-top: 30px;
}

.btnpadding {
  padding: 0 20px;
}

.image {
  width: 13vh;
  height: 13vh;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  position: relative;
  z-index: 1000;
}
.inspired-required {
  color: red;
  font-size: 12px;
  padding-top: 4px;
  padding-left: 14px;
}
.inspired-error {
  border: 1px solid rgba(240, 52, 52, 0.4);
}
.hide {
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
}
.text-underline {
  color: #000;
}
.text-underline:hover {
  color: #d4e245;
  text-decoration: underline;
  -webkit-text-decoration-color: #d4e245;
  text-decoration-color: #d4e245;
}
.inspired-image {
  width: 30px;
}
.inspired-loader {
  position: relative;
  height: 350px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inspired-loader .loader {
  width: auto;
  height: 100%;
  position: initial;
  z-index: 2;
}
.inspired-message {
  height: 290px;
  overflow: auto;
  word-break: break-word;
  padding-top: 40px;
}
.inspired-bottom {
  margin-bottom: 15px;
}

@media screen and (min-width: 767px) {
  .inspired {
    margin: 0 auto;
    padding: 20px 40px;
  }
  .inspired-images {
    position: absolute;
    left: -45px;
    top: -40px;
  }
  .inspired-images .thumbnail_link {
    width: 5em;
    height: 5em;
  }
}
