.modal {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
}

.modal.landscape {
  overflow-y: hidden;
  overflow-x: auto;
  top: 0;
}

.modal .background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.btn-modal {
  border: none;
  background: #000;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: #d4e147 0.2em solid;
  position: absolute;
  left: 10%;
  top: 20%;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-modal svg {
  width: 36px;
  height: 36px;
  font-weight: 900;
}

@media (min-width: 768px) {
  .btn-modal {
    width: 70px;
    height: 70px;
    border: #d4e147 0.3em solid;
  }
  .btn-modal svg {
    width: 40px;
    height: 40px;
  }
}
