.landscape .canvas {
  transform: rotate(90deg);
  top: auto;
}
.canvas {
  display: block;
  width: 100%;
  height: 100%;
  background-image: url('../../assests/img/surface.svg');
  background-repeat: no-repeat;
  position: absolute;
  background-position: center;
  z-index: -1;
  transform: rotate(0deg);
  top: 0;
}
.gray{
  background-image: url('../../assests/img/surface-gray.svg');
}
.transformTransition{
  transition: transform 500ms;
}
