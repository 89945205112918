.container-detail {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  /* top: 21vh; */
  position: relative;
}

.styles_modal__gNwvD2 {
  background-color: transparent;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow-x: scroll;
  overflow-y: scroll;
}

.styles_overlay__CLSq-1 {
  /* border: solid red 1px; */
  z-index: 1029;
  padding: 0;
}

/* @media screen and (orientation: portrait) {
  .styles_modal__gNwvD2 {
    top: 8vh;
  }
} */
.styles_overlay__CLSq- {
  background-color: transparent !important;
}
