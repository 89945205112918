img[alt="Avatar-signOut"] {
  max-width: 55px;
  border-radius: 50%;
  padding: 4px;
  border: 3px solid #3d3b39;
  height: 52px;
}

.avatar-desktop {
  color: white;
  font-size: 15px;
  vertical-align: middle;
  margin-left: 12px;
  font-weight: 100;
  letter-spacing: 1.4px;
}

.options-modal {
  margin-right: 15px;
  color: white;
  font-size: 10px;
  cursor: pointer;
  letter-spacing: 1.4px;
}

.options-modal:last-child {
  margin-right: 0;
}

.notifications {
  margin-left: 30px;
}

.notifications-modal {
  vertical-align: middle;
  position: absolute;
  background-color: #91cae5;
  border: none;
  width: 20px;
  height: 20px;
  left: 88px;
  max-width: 20px;
  text-align: center;
  line-height: 19px;
  font-size: 12px;
  font-weight: 700;
  color: #262626;
  border-radius: 50%;
  margin-top: 4px;
}

.styles_modal__gNwvD3 {
  background-color: #212020;
  position: absolute;
  top: 0;
  width: 400px;
  right: 0;
  border-bottom-left-radius: 40px;
  border-top-left-radius: 40px;
  border-bottom-right-radius: 40px;
  border-top-right-radius: 40px;
}

.user-menu_wrapper{
  position: relative;
}
.user-menu_select {
  background: #2b2b2b;
  border: none;
  border-radius: 20px;
  color: #fff;
  height: 40px;
  padding: 0 12px;
  width: 100%;
}
.user-menu_wrapper::after {
  background: #d6dd3d;
  border-radius: 50%;
  color: #2b2b2b;
  content: "▼";
  font-size: 10px;
  height: 18px;
  padding: 3px;
  pointer-events: none;
  position: absolute;
  right: 8px;
  top: calc(41% - 8px);
  width: 18px;
  display: flex;
  justify-content: center;
}
