.sign-in-actions {
  padding-right: 15px;
  padding-left: 8px;
  font-size: 11px;
  font-weight: normal;
  color: white;
  font-family: HelveticaNeue;
  text-align: inherit;
  line-height: 30px;
}

.log-in,
.sign-up {
  height: 30px;
  margin-right: 15px;
  display: inline-flex;
  cursor: pointer;
}

.log-in {
  border-right: 1.5px dotted #717171;
}

.sign-in-instructions {
  color: white;
  font-weight: 80;
  font-size: 11px;
  letter-spacing: 0.5px;
  outline: none;
  font-family: HelveticaNeue;
  text-align: inherit;
  margin-right: 20px;
}

img[alt="menu-hamburger"] {
  width: 42px;
}

.icon-login-search {
  margin-right: 15px;
}

.icon-login-search:first-child {
  margin-left: 100px;
}

img[alt="Login with"],
img[alt="signup with"] {
  transform: rotate(180deg);
}

h3[class="text-item-modal"] {
  margin-top: 50px;
  color: white;
  font-size: 20px;
}

.styles_closeButton__20ID4 {
  fill: black;
  cursor: pointer;
  background: #D6DD3D;
  padding: 5px;
  border-radius: 50%;
  top: 14px;
}

.align-with-icons {
  margin: 25px 15px 10px 0;
}

@media only screen and (max-width: 480px) and (min-width: 319px) {
  .navbar-sm {
    padding-left: 10px;
  }

  .padding-share-sm {
    padding-left: 0;
  }

  .navbar {
    padding: 5px 20px;
    background-color: transparent;
    max-height: 10px;
  }
}
