.lb {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0,0,0,0.7);
    z-index: 100;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
  }
  .lb.on {
    visibility: visible;
    opacity: 1;
  }
  .lb .message {
    border-radius: 160px;
    width: 320px;
    height: 320px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .lb .message.welcome {
    background-color: color_violet;
    color: #fff;
    font-size: 1em;
    text-align: center;
  }
  .lb .message.welcome .content {
    display: table-cell;
    vertical-align: middle;
    width: 320px;
    height: 320px;
    padding: 50px;
  }
  .lb .message.welcome .title {
    font-size: 1em;
  }
  .lb .message.welcome .title .user {
    font-weight: bold;
  }
  .lb .message.welcome .walker {
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
    background: url("../../assests/img/walker.png") no-repeat;
    height: 140px;
    width: 124px;
  }
  .lb .message.welcome .action {
    font-size: 1.4em;
  }
  .lb .message.almost {
    background-color: #91CAE5;
    color: #000;
    font-size: 1.2em;
    text-align: center;
  }
  .lb .message.almost .content {
    display: table-cell;
    vertical-align: middle;
    width: 320px;
    height: 320px;
    padding: 35px;
  }
  .lb .message.almost .title {
    font-size: 1.5em;
    margin-bottom: 5px;
  }
  lb .message.almost .action {
    position: absolute;
    bottom: 30px;
    right: 50%;
  }
  .lb .message.almost .action a {
    text-align: center;
    font-size: 10px;
    text-transform: uppercase;
    background: transparent;
    border: none;
    padding: 3px;
    cursor: pointer;
    font-weight: bold;
    color: #000;
  }
  .lb .message.check {
    background-color: #91CAE5;
    color: #000;
    font-size: 0.8em;
  }
  .lb .message.check .content {
    display: table-cell;
    vertical-align: middle;
    width: 320px;
    height: 320px;
    padding: 35px;
  }
  .lb .message.check .title {
    font-size: 1.2em;
    margin-bottom: 15px;
    margin-left: 27px;
  }
  .lb .message.check ul li {
    list-style-position: inside;
  }
  .lb .message.check .action {
    position: absolute;
    bottom: 30px;
    right: 50%;
  }
  .lb .message.check .action a {
    text-align: center;
    font-size: 10px;
    text-transform: uppercase;
    background: transparent;
    border: none;
    padding: 3px;
    cursor: pointer;
    font-weight: bold;
    color: #000;
  }
  .lb .message.check .action a:hover {
    text-decoration: none;
  }
  .lb .messageShare {
    border-radius: 260px;
    width: 390px;
    height: 390px;
    position: fixed;
    top: 59%;
    left: 51%;
    transform: translate(-50%, -50%);
  }
  .lb .messageShare.share {
    background-color: #262626;
    color: #000;
    font-size: 1em;
  }
  .lb .messageShare.share .content {
    display: table-cell;
    vertical-align: middle;
    width: 320px;
    height: 320px;
    padding-top: 50px;
    padding-left: 100px;
  }
  .lb .messageShare.share .content .google {
    z-index: 10000;
  }
  .lb .messageShare.share .content .google .logo {
    background-image: url("../../assests/img/google_on.png");
    background-repeat: no-repeat;
    color: color_google;
    width: 36px;
    height: 36px;
    display: block;
  }
  .lb .messageShare.share .content .google span {
    color: color_google;
  }
  .lb .messageShare.share .content .google span:hover {
    color: #007eef;
  }
  .lb .messageShare.share .content .google .name {
    margin-left: 42px;
    top: 26px;
  }
  .lb .messageShare.share .content .facebook {
    cursor: pointer;
  }
  .lb .messageShare.share .content .facebook .logo {
    background-image: url("../../assests/img/facebook_on.png");
    background-repeat: no-repeat;
    width: 36px;
    height: 36px;
    display: block;
  }
  .lb .messageShare.share .content .facebook span {
    color: color_facebook;
  }
  .lb .messageShare.share .content .facebook span:hover {
    color: #2b57a0;
  }
  .lb .messageShare.share .content .facebook .name {
    margin-left: 42px;
    top: 26px;
  }
  .lb .messageShare.share .content .twitter .logo {
    background-image: url("../../assests/img/twitter_on.png");
    background-repeat: no-repeat;
    width: 36px;
    height: 36px;
    display: block;
  }
  .lb .messageShare.share .content .twitter span {
    color: color_twitter;
  }
  .lb .messageShare.share .content .twitter span:hover {
    color: #0cf;
  }
  .lb .messageShare.share .content .twitter .name {
    margin-left: 42px;
    top: 26px;
  }
  .lb .messageShare.share .content .email .logo {
    background-image: url("../../assests/img/email_on.png");
    background-repeat: no-repeat;
    width: 36px;
    height: 36px;
    display: block;
  }
  .lb .messageShare.share .content .email span {
    color: color_email;
  }
  .lb .messageShare.share .content .email .name {
    margin-left: 42px;
    top: 26px;
  }
  .lb .messageShare.share .title {
    font-size: 1.2em;
    margin-bottom: 25px;
    color: #fff;
  }
  .lb .messageShare.share ul li {
    list-style-position: inside;
  }
  .lb .messageShare.share .action {
    position: absolute;
    bottom: 30px;
    right: 50%;
  }
  .lb .messageShare.share .action a {
    text-align: center;
    font-size: 10px;
    text-transform: uppercase;
    background: transparent;
    border: none;
    padding: 3px;
    cursor: pointer;
    font-weight: bold;
    color: #000;
  }
  .lb .messageShare.share .action a:hover {
    text-decoration: none;
  }
  .lb .loginWith {
    width: 390px;
    height: 400px;
    position: fixed;
    top: 50%;
    left: 51%;
    transform: translate(-51%, -50%);
  }
  .lb .loginWith.share {
    background-color: #000;
    color: #000;
    font-size: 1em;
  }
  .lb .loginWith.share .content {
    display: table-cell;
    vertical-align: middle;
    width: 320px;
    height: 320px;
    padding-top: 50px;
    padding-left: 100px;
  }
  .lb .loginWith.share .content .google {
    z-index: 10000;
  }
  .lb .loginWith.share .content .google .logo {
    background-image: url("../../assests/img/google_on.png");
    background-repeat: no-repeat;
    color: color_google;
    width: 36px;
    height: 36px;
    display: block;
  }
  .lb .loginWith.share .content .google span {
    color: color_google;
  }
  .lb .loginWith.share .content .google span:hover {
    color: #007eef;
  }
  .lb .loginWith.share .content .google .name {
    margin-left: 42px;
    top: 26px;
  }
  .lb .loginWith.share .content .facebook {
    cursor: pointer;
  }
  .lb .loginWith.share .content .facebook .logo {
    background-image: url("../../assests/img/facebook_on.png");
    background-repeat: no-repeat;
    width: 36px;
    height: 36px;
    display: block;
  }
  .lb .loginWith.share .content .facebook span {
    color: color_facebook;
  }
  .lb .loginWith.share .content .facebook span:hover {
    color: #2b57a0;
  }
  .lb .loginWith.share .content .facebook .name {
    margin-left: 42px;
    top: 26px;
  }
  .lb .loginWith.share .content .twitter .logo {
    background-image: url("../../assests/img/twitter_on.png");
    background-repeat: no-repeat;
    width: 36px;
    height: 36px;
    display: block;
  }
  .lb .loginWith.share .content .twitter span {
    color: color_twitter;
  }
  .lb .loginWith.share .content .twitter span:hover {
    color: #0cf;
  }
  .lb .loginWith.share .content .twitter .name {
    margin-left: 42px;
    top: 26px;
  }
  .lb .loginWith.share .content .email .logo {
    background-image: url("../../assests/img/email_on.png");
    background-repeat: no-repeat;
    width: 36px;
    height: 36px;
    display: block;
  }
  .lb .loginWith.share .content .email span {
    color: color_email;
  }
  .lb .loginWith.share .content .email .name {
    margin-left: 42px;
    top: 26px;
  }
  .lb .loginWith.share p {
    color: #fff;
    font-size: 11px;
  }
  .lb .loginWith.share .title {
    font-size: 1.2em;
    margin-bottom: 25px;
    color: #fff;
  }
  .lb .loginWith.share ul li {
    list-style-position: inside;
  }
  .lb .loginWith.share .action {
    position: absolute;
    bottom: 0px;
    right: 44%;
  }
  .lb .loginWith.share .action a {
    text-align: center;
    font-size: 10px;
    text-transform: uppercase;
    background: transparent;
    border: none;
    padding: 3px;
    cursor: pointer;
    font-weight: bold;
    color: #000;
  }
  .lb .loginWith.share .action a:hover {
    text-decoration: none;
  }
  