@tailwind base;
@tailwind components;
@tailwind utilities;


.fixed {
  position: fixed;
}

.blur {
}

.chat-container {
  padding-top: 50px; /* Reduced by half */
}

.blur .chat-container {
  filter: blur(5px);
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.notify-container {
  z-index: 99999;
}

html,
body {
  font-size: 20px;
  color: rgb(255 255 255 / 70%);
  display: block;
  transition: filter 0.3s ease;
  margin: 0;
  font-family: HelveticaNeue-Thin, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000; /* Black background color */
}
html {
  -webkit-font-smoothing: subpixel-antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6,
select,
input,
a {
  font-family: HelveticaNeue-Thin;
  color: #fff;
}

button {
  font-family: HelveticaNeue-bold;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  color: #000;
  text-decoration: none;
}

a:hover {
  color: var(--primary-color);
}

input:focus,
select:focus {
  outline: none;
}

:root {
  --primary-color: #d6dd3d;
  scroll-behavior: unset;
}
.osano-cm-widget{
  display: none;
}
.locked {
  height: 100%;
  overflow: hidden;
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}
#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@font-face {
  font-family: HelveticaNeue-Thin;
  src: url("./assests/fonts/HelveticaNeue-Thin.ttf");
}

@font-face {
  font-family: HelveticaNeue;
  src: url("./assests/fonts/HelveticaNeue-Regular.otf");
}

@font-face {
  font-family: HelveticaNeue-Bold;
  src: url("./assests/fonts/HelveticaNeue-Bold.otf");
}

@font-face {
  font-family: HelveticaNeue-Light;
  src: url("./assests/fonts/HelveticaNeue-Light.otf");
}

@font-face {
  font-family: HelveticaNeue-Regular;
  src: url("./assests/fonts/HelveticaNeue-Regular.otf");
}

.main-section {
  padding: 80px 20px
}

.price-img_fluid {
  max-width: 100%;
}

.btn {
  font-size: 12px;
  color: #000;
  border-radius: 30px;
  padding: 8px 35px;
  font-family: HelveticaNeue-Bold;
  text-transform: uppercase;
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  animation-delay: 0.85s;
  -webkit-animation-delay: 0.85s;
}

.btn:hover,
.btn:first-child:hover,
:not(.btn-check) + .btn:hover {
  color: #000;
  background-color: #fff;
  border-color: var(--primary-color);
}

.section-title {
  margin-bottom: 50px;
}

.section-title h1 {
  font-size: 38px;
  font-weight: 400;
  color: rgb(255 255 255 / 90%);
  margin-bottom: 20px;
}

/*header*/

.header {
  padding: 10px 0;
  position: fixed;
  width: 100%;
  z-index: 99;
  background-color: #000;
}

.header-logo {
  max-width: 250px;
}

.header-content {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.header .btn {
  padding: 8px 70px;
}

/* about-section */

.about-section {
  overflow: hidden;
}

.about {
  margin-bottom: 100px;
}

.about:last-child {
  margin-bottom: 0;
}

.thumb-title {
  display: none;
  margin-bottom: 20px;
}

.about-text {
  align-self: center;
  max-width: 580px;
}

.about-text p {
  max-width: 500px;
}

.about-section h1 {
  font-size: 38px;
  font-weight: 400;
  color: rgb(255 255 255 / 90%);
  margin-bottom: 20px;
}

/* team-section */

.team-section {
  overflow: hidden;
}

.team-logo {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  align-items: center;
  margin-bottom: 50px;
}

.team-logo .logo {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 20px;
  -ms-flex: 0 0 16.66666667%;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

/* video-section */

.video-thumb {
  height: 570px;
  position: relative;
  background-color: #fff;
}

.video-thumb iframe {
  width: 100%;
  height: 100%;
}

/* pricing-section */

.price {
  margin-bottom: 30px;
}

.price-content {
  text-align: center;
}

.price-header {
  text-align: left;
  margin-bottom: 30px;
}

.price-header .price-image {
  overflow: hidden;
  text-align: center;
  max-width: 100%;
}

.price-content h2 {
  font-size: 20px;
  margin-bottom: 40px;
  color: rgb(255 255 255 / 90%);
  font-family: HelveticaNeue-Bold;
  text-transform: uppercase;
}

.price-content ul {
  margin-bottom: 50px;
}

.price-content ul li {
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 5px;
}

.price-content ul li:last-child {
  margin-bottom: 0;
}

.pricing-section .button .btn {
  padding: 10px 50px;
}

/*footer-section*/

.footer-section {
  color: #fff;
  padding: 50px 0;
  background-color: #917abd;
  position: relative;
}

.footer-widget {
  color: #fff;
  font-size: 14px;
}

.footer-menu ul {
  display: -ms-flexbox;
  display: flex;
}

.footer-menu ul li {
  color: #fff;
  text-transform: capitalize;
  margin-right: 16px;
}

.footer-widget ul li:last-child {
  margin-right: 0;
}

.footer-widget a,
.footer-widget ul li a {
  color: #fff;
}

.footer-social ul {
  display: -ms-flexbox;
  display: flex;
}

.footer-social ul li {
  font-size: 25px;
  margin-right: 20px;
}

.footer-social ul li img:hover {
  -webkit-filter: invert(30%) grayscale(100%) brightness(60%) contrast(4);
  filter: invert(30%) grayscale(100%) brightness(60%) contrast(4);
}

.footer-widget ul li a:hover,
.footer-widget a:hover {
  color: #000;
}

.footer-widget p {
  margin-bottom: 0;
}

/*Magnific-popup overwrite CSS*/

.mfp-iframe-holder .mfp-content {
  border-radius: 5px;
  border: 10px solid #fff;
}

.mfp-iframe-holder .mfp-close {
  top: -45px;
  right: -20px;
}

.mfp-figure {
  background-color: #fff;
}

img.mfp-img {
  padding: 12px;
}

.mfp-image-holder .mfp-close {
  top: -35px;
}

.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: pointer;
}

.mfp-counter {
  right: 50%;
  color: #fff;
  top: 50px;
  margin-right: -20px;
}

/* page loader  */
#preloader {
  position: fixed;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  height: 100vh;
  width: 100vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.loader3 {
  width: 5rem;
  height: 5rem;
  display: inline-block;
  padding: 0rem;
  text-align: left;
}

.loader3 span {
  position: absolute;
  display: inline-block;
  width: 5rem;
  height: 5rem;
  border-radius: 100%;
  background: #d6dd3d;
  -webkit-animation: loader3 1.5s linear infinite;
  animation: loader3 1.5s linear infinite;
}

.loader3 span:last-child {
  animation-delay: -0.9s;
  -webkit-animation-delay: -0.9s;
}

@keyframes loader3 {
  0% {
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
    opacity: 0.8;
  }

  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 0;
  }
}

@-webkit-keyframes loader3 {
  0% {
    -webkit-transform: scale(0, 0);
    opacity: 0.8;
  }

  100% {
    -webkit-transform: scale(1, 1);
    opacity: 0;
  }
}

.climateTitle {
  color: #d6de3d;
  font-size: 40px;
  font-weight: 200;
  width: 312px;
  margin: 0 auto;
}

.climate-information-ideas,
.climate-information-innovation {
  text-align: center;
  font-size: 20px;
  font-weight: 300;
  width: 314px;
  margin: 0 auto;
  margin-bottom: 40px;
  font-family: HelveticaNeue-Light;
}

.climate-information-apply {
  text-align: center;
  font-size: 20px;
  font-weight: 300;
  width: 314px;
  margin: 0 auto;
  margin-bottom: 40px;
  font-style: italic;
  font-family: HelveticaNeue-Light;
}

.climate-ctn {
  margin-top: 40px;
}
.climate-image {
  text-align: center;
  margin: 40px 0 40px;
}

.climate-dialog-input {
  margin: 30px auto;
  width: 284px;
  position: relative;
}

.climate-ideas-input {
  height: 40px;
  width: 100%;
  border-radius: 14px;
  background: #b9b9b9;
  border: none;
  padding: 0 12px;
  color: #2b2b2b;
  font-weight: 300;
  font-family: HelveticaNeue;
}

.climate-form {
  text-align: left;
}

.climate-dialog-label {
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 16px;
  letter-spacing: 1px;
  font-family: HelveticaNeue-Bold;
  text-transform: uppercase;
}

.climate-ideas-button {
  background: #d6dd3d;
  border: none;
  height: 40px;
  border-radius: 14px;
  color: black;
  min-width: 150px;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 80px;
  display: block;
  cursor: pointer;
  font-family: HelveticaNeue-Bold;
}

.climate-message-information {
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0 30px 30px;
}

.climate-soon-message {
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 50px 30px 90px;
}

.climate-required {
  font-size: 14px;
  font-weight: 700;
  margin: 10px 5px;
  color: rgb(211, 55, 55);
}

@media (min-width: 890px) {
  .climate-ctn {
    margin-top: 70px;
  }
  .climateTitle {
    font-size: 60px;
    width: 100%;
    text-align: center;
  }
  .climate-information-ideas,
  .climate-information-innovation {
    color: #fff;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    width: 885px;
  }
  .climate-information-apply {
    color: #fff;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    width: 885px;
    font-style: italic;
  }
  .climate-dialog-input {
    width: 420px;
    margin: 25px auto;
  }
  .climate-message-information,
  .climate-soon-message {
    color: #fff;
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
}

@media (min-width: 1400px) {
  .header .container {
    max-width: 1380px;
  }

  .container {
    max-width: 1250px;
  }
}

/* md */
@media (min-width: 1200px) and (max-width: 1400px) {
}

/* md */
@media (min-width: 1380px) {
}

/* md */
@media (min-width: 992px) and (max-width: 1199px) {
  .footer-menu ul.footer-list li {
    margin-right: 10px;
  }

  .footer-social ul {
    justify-content: center;
  }

  .about-section .about-text > h1 {
    font-size: 30px;
  }
}

/* 992-1199 */

/* md */
@media (max-width: 991px) {
  .about-section .about {
    display: block;
  }

  .about-section .about .thumb {
    margin-bottom: 30px;
  }

  .about-section .about .thumb,
  .about-section .about .about-text {
    max-width: 100%;
  }

  .video-thumb {
    height: 470px;
  }

  .footer-social {
    margin: 30px 0;
  }

  .video-link {
    font-size: 30px;
  }

  h1.thumb-title {
    display: block;
  }

  .about-text h1 {
    display: none;
  }
}

/* 991 */

/* sm */
@media (min-width: 768px) and (max-width: 991px) {
}

/* 768-991 */

/* sm */
@media (max-width: 767px) {
  .about-section h1.thumb-title,
  .section-title h1 {
    font-size: 30px;
  }

  .main-section {
    padding: 50px 0;
  }

  .video-thumb {
    height: 380px;
  }

  .team-logo .logo {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}

/* 767 */

/* xs Portrait */
@media (max-width: 575px) {
  .team-logo .logo {
    -ms-flex: 0 0 33.33333333%;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .footer-menu ul {
    display: block;
  }

  .btn {
    font-size: 10px;
  }

  .header .btn {
    padding: 8px 30px;
  }

  .header-logo {
    max-width: 200px;
  }

  .about-section h1.thumb-title,
  .section-title h1 {
    font-size: 24px;
  }

  .pricing-section .section-title {
    margin-bottom: 0;
  }

  .footer-widget p a {
    display: block;
    margin-top: 30px;
  }

  .about-section .about .thumb {
    text-align: center;
  }

  .about {
    margin-bottom: 50px;
  }
}

/* 575 */

/* xs Portrait */
@media (max-width: 475px) {
  .team-logo .logo {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .team-logo .logo:nth-child(even) {
    text-align: right;
  }

  .header-logo {
    max-width: 150px;
  }
}

/* 475 */
