.seenapse {
  height: 90%;
  width: 60%;
  display: grid;
  grid-template-rows: 15% 55% 30%;
  font-size: 1.21vh;
}
.seenapse header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.seenapse footer {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.seenapse p {
  font-size: 1em;
  margin: 0;
}
.seenapse section {
  position: relative;
  display: grid;
  grid-template-rows: 30% 40% 30%;
  align-items: center;
  justify-items: center;
}
.seenapse section canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
.seenapse .seenapseImg {
  width: 13vh;
  height: 13vh;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  position: relative;
  z-index: 1000;
}

.seenapse .seenapseImg span{
  visibility: hidden;
}

.seenapse .content {
  width: 40%;
  height: 95%;
  display: grid;
  grid-template-rows: 1fr 2fr 1fr;
  position: relative;
}
.seenapse .content p{
  font-size: 1em;
  overflow-x: hidden;
  overflow-y: auto;
  font-weight: 400;
  text-align: center;
  color: black;
  margin: 0 auto 1em;
}

.seenapse .content p.error{
  font-size: 0.8em;
  margin: 0;
  overflow: visible;
  color: red;
  text-align: center;
}

.seenapse .content h2 {
  font-size: 1.1875em;
  text-align: center;
  text-align: center;
  font-weight: 700;
  color: black;
  align-self: end;
}

.seenapse .content .menu {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: 1em auto 0;
  padding: 0;
  align-self: end;
}

.seenapse .content .menu li{
  display: flex;
  align-items: center;
}

.seenapse .content .menu .avatar {
  width: 2.6875em;
  height: 2.6875em;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
}

.landscape .seenapse {
  width: 90%;
  height: 60%;
  display: grid;
  grid-template-columns: 15% 55% 30%;
  grid-template-rows: 100%;
  font-size: 1.21vw;
}

.landscape .seenapse header{
  justify-content: center;
}
.landscape .seenapse section {
  grid-template-columns: 30% 40% 30%;
  grid-template-rows: 100%;
}
.landscape .seenapse footer{
  justify-content: center;
  align-items: flex-start;
  margin-top: 0;
  margin-left: 1em;
}

.landscape .seenapse .seenapseImg {
  width: 10.7em;
  height: 10.7em;
}
.landscape .seenapse .content {
  width: 95%;
  height: 50%;
}

.landscape .seenapse p {
  text-align: center;
}

@media(min-width:768px){
  .seenapse .content{
    width: 50%;
    height: 90%;
  }
}
@media(min-width: 960px){
  .landscape .seenapse .content {
    width: 95%;
    height: 55%;
  }
}
@media(min-width: 1280px){
  .landscape .seenapse .content {
    width: 95%;
    height: 55%;
  }
}
@media(min-width:1024px){
  .seenapse .content h2{
    font-size: 1.5em;
  }
  .seenapse .content p{
    font-size: 1.2em;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 823px) and (orientation : portrait) {
  .seenapse .content {
    width: 65%;
  }
}
@media (min-aspect-ratio: 1/1) {
  .seenapse .content {
    width: 40%;
  }
  .landscape .seenapse .content {
    height: 40%;
    width: 85%;
  }
}
@media (min-aspect-ratio: 1/2) and (max-device-width: 1024px) {
  .seenapse .content {
    width: 57%;
  }
}
@media (min-aspect-ratio: 1/2) and (min-width: 768px) {
  .seenapse .content {
    width: 50%;
  }
}
@media (min-aspect-ratio: 4/3) {
  .landscape .seenapse .content {
    height: 45%;
  }
}
@media (min-aspect-ratio: 16/9) {
  .landscape .seenapse .content {
    height: 65%;
  }
}
