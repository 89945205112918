  .theme-orange {
    color: #EF9D49;
  }
  
  a {
    color: #f4f4f4;
    text-decoration: none;
  }

  .action {
    font-size: 10px;
    text-transform: uppercase;
    color: #3a505d;
    text-align: right;
  }

  form {
    text-align: center;
    font-size: 1em;
    font-weight: 200;
  }
  .login-email-form {
    color: #717171;
  }
  .login-email-form a:hover {
    color: #ef9d49;
  }
  .login-email-form form {
    max-width: 400px;
    margin: 0 auto;
    padding: 100px 15px;
  }
  .login-email-form fieldset {
    outline: 0;
    border: 0;
    font-size: 1.3em;
  }
  .login-email-form label {
    display: inline-block;
    vertical-align: middle;
    clear: both;
    float: left;
    clear: none;
    text-align: inherit;
    width: 33.33333333333333%;
    margin-left: 0%;
    margin-right: 0%;
  }
  .login-email-form button {
    font-size: 10px;
    text-transform: uppercase;
    background: transparent;
    border: none;
    padding: 3px;
    color: #717171;
    cursor: pointer;
    font-weight: bold;
  }
  
  .login-email-form .field:before, .login-email-form .field:after {
    content: ' ';
    display: table;
  }

  .login-email-form .field {
    margin-bottom: 64px;
    clear: both;
  }
  
  .login-email-form .action {
    font-size: 10px;
    text-transform: uppercase;
    color: #3a505d;
    text-align: right;
  }
  .login-email-form .action .right {
    margin-left: 20px;
    color: #91CAE5;
  }
  .login-email-form input[type='text'],
  .login-email-form input[type='password'],
  .login-email-form input[type='email'] {
    background-color: #2b2b2b;
    color: #fff;
    padding: 7px 5px;
    border: 1px solid #717171;
    display: inline-block;
    vertical-align: middle;
    clear: both;
    float: left;
    clear: none;
    width: 66.66666666666666%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 12px;
  }
  .login-email-form .errorForm {
    border: 1px solid #91CAE5;
    border-color: #91CAE5 !important;
  }
  