.content-avatar {
  width: inherit;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-user {
  justify-content: flex-start;
  width: unset;
}

.content-avatar figure {
  margin: 0;
  padding: 0;
}

.content-avatar img {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  cursor: pointer;
  padding: 4px;
  border: 3px solid #3d3b39;
}

.total-notifications {
  background-color: #91cae5;
  border: none;
  width: 20px;
  height: 20px;
  max-width: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  font-weight: 700;
  color: #262626;
  border-radius: 50%;
  margin-left: -10px;
}

.user-name {
  padding-left: 23px;
  width: 139px;
  padding-bottom: 0;
}
