header {
  width: 100%;
  background-color: transparent;
  transition: filter 300ms ease-out;
}

.right-form {
  right: 5rem;
}

.avatar-form-group {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-hidden {
  display: none;
}

.header-summary {
  position: absolute;
  top: 0;
}

.header-summary-desktop {
  position: fixed;
  top: 10px;
  z-index: 1037;
}

.navbar {
  padding: 15px 25px;
  background-color: transparent;
  max-height: 120px;
  height: 100px;
}

a[class="navbar-brand img-fluid"] {
  cursor: pointer;
}

.img-fluid {
  max-width: 50%;
}

.img-fluid > img{
  width: 240px;
}

.container-login-links-search {
  right: 151px;
  top: 33px;
  position: absolute;
}

.icon-login {
  display: inline-block;
  cursor: pointer;
}

.icon-login span {
  font-size: 13px;
  letter-spacing: 1px;
  outline: none;
  font-family: HelveticaNeue;
  font-weight: 300;
  color: #717171;
  padding: 0 18px 0 6px;
}

.instructions {
  margin-top: 10px;
  color: white;
  font-weight: 80;
  position: relative;
  letter-spacing: 0.5px;
  outline: none;
  font-family: HelveticaNeue;
  text-align: inherit;
  padding: 0 !important;
}

.logo-hidden {
  display: none;
}
