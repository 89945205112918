.container-img-404 {
  margin: 60px 25px;
}

.img-404 {
  height: auto;
  background-size: contain;
  background-repeat: no-repeat;
}

.text-404 > h3,
p {
  font-family: HelveticaNeue-Light;
  font-weight: 200;
  color: #fff;
}
