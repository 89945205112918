.container-comments{
  width: 100%;
}

.comments{
  width: 324px;
  height: 283px;
  padding: 20px 14px 20px 14px;
  background: #84D0EF;
  border-radius: 50px;
  margin-right: 20px;
  position: relative;
}

.comments-title {
  margin: 0 auto;
  color: #000;
  line-height: 22px;
  font-size: 18px;
  height: 180px;
  overflow: hidden;
}

.comments-data{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.comments-data-image {
  margin: 0;
  padding: 0;
  bottom: -20px;
  width: 85px;
  height: 85px;
  position: absolute;
}

.comments-data-image img{
  width: 100%;
  height: 100%;
}

.comments-data-info{
  margin-left: 100px;
  position: relative;
  margin-top: 30px;
  font-size: 16px;
}

.comments-name{
  padding: 0;
  margin: 0;
  color: #000;
  font-family: HelveticaNeue-Bold;
}

.comments-position{
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 190px;
}

.comments-slide {
  padding-bottom: 50px;
}

.comments-slide div.slick-list {
  margin-bottom: 50px;
  height: 305px !important;
}

.comments-slide div.slick-slide {
  margin-bottom: 50px;
}
@media screen and (min-width: 375px) {
  .comments {
    width: 340px;
    height: 283px;
  } 
}

@media screen and (min-width: 428px) {
  .comments {
    width: 390px;
    height: 283px;
    padding: 20px 20px 20px 32px;
  } 
}
@media screen and (min-width: 520px) {
 .comments {
    width: 483px;
    height: 214px;
 } 
 .comments-slide div.slick-list {
    height: 240px !important;
  }
  .comments-title {
    height: 110px;
  }
  .comments-position{
    font-size: 16px;
    width: 100%;
  }
}