.logo-links {
  display: inline-block;
  width: 36px;
  height: 36px;
  vertical-align: middle;
}

.container-login-links {
  position: absolute;
  right: 161px;
  top: 35px;
}

.icon-email {
  background-image: url('../../assests/img/email.png');
  background-repeat: no-repeat;
}

.icon-twitter {
  background-image: url('../../assests/img/twitter.png');
  background-repeat: no-repeat;
}

.icon-facebook {
  background-image: url('../../assests/img/facebook.png');
  background-repeat: no-repeat;
}

.icono-google {
  background-image: url('../../assests/img/google.png');
  background-repeat: no-repeat;
}

.icono-google:focus,
.styles_closeButton__20ID4:focus {
  outline: 0;
}

.login-links .icon-login:hover .icon-email {
  background-image: url('../../assests/img/email_on.png');
  background-repeat: no-repeat;
}

.login-links .icon-login:hover .email {
  color: #8166a8;
}

.login-links .icon-login:hover .icon-twitter {
  background-image: url('../../assests/img/twitter_on.png');
  background-repeat: no-repeat;
}

.login-links .icon-login:hover .twitter {
  color: #2091c2;
}

.login-links .icon-login:hover .icon-facebook {
  background-image: url('../../assests/img/facebook_on.png');
  background-repeat: no-repeat;
}

.login-links .icon-login:hover .facebook {
  color: #214a8a;
}

.login-links .icon-login:hover .icono-google {
  background-image: url('../../assests/img/google_on.png') !important;
  background-repeat: no-repeat !important;
}

.google {
  padding-right: 0 !important;
}

.login-links .icon-login:hover .google {
  color: #046ccf;
}
