.spinner {
  border: 3px solid rgba(255, 255, 255, 0.1);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border-left-color: #D6DD3D;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}