body {
    overflow-x: hidden;
}

.inspired-message {
    max-height: 424px;
    height: 95%;
    padding-top: 40px;
    word-break: break-word;
}

.inspired-ideas-modal {
    max-width: 960px!important;
    width: 80%;
    max-width: 350px;
    height: 555px;
    border-radius: 50px;
    background: white;
    position: absolute;
    z-index: 999;
    margin: auto;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    border-radius: 56px;
    padding: 24px 60px 24px 24px;
    top: -174px;
}

.inspired-ideas-modal h1 {
    font-size: 38px;
    line-height: 46px;
    margin-top: 41px;
    letter-spacing: 0px;
    font-weight: 200;
    color: #000000;
    font-family: HelveticaNeue-Light;
}

.inspired-ideas-modal p {
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0px;
    color: #000000;
    font-family: HelveticaNeue-Light;
}

.inspired-ideas-button {
    background-color: #D6DD3D;
    width: 183px;
    color: #000000;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
}

.inspired-ideas-close {
    background-color: #D6DD3D;
    border-radius: 50%;
    width: 34px;
    height: 34px;
    cursor: pointer;
    position: absolute;
    right: 24px;
    top: 24px;
    display: flex;
    justify-content: center;
    font-size: 21px;
    color: #2B2B2B;
    font-family: monospace;
}

.beforeGeneratingIdeasForm {
    font-family: HelveticaNeue-Light;
    font-weight: 300;
}

.border-red {
    border: 3px red solid;
}

.container-before-generating-ideas {
    padding-right: 25px;
    margin-top: 100px;
    padding-left: 57px;
}

.container-before-generating-ideas h1 {
    color: #fff;
    font-size: 38px;
    line-height: 46px;
    font-weight: 300;
    font-family: inherit;
    text-align: left;
}

.container-before-generating-ideas p {
    text-align: left;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0px;
    color: #fff;
    font-weight: 300;
    font-family: inherit;
}

.generate-ideas-button {
    background: #D6DD3D;
    border: none;
    height: 40px;
    border-radius: 20px;
    color: black;
    min-width: 150px;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    margin-top: 20px;
    display: block;
}

select {
    -webkit-appearance: none;
    appearance: none;
}

.container-before-generating-ideas .select-wrapper {
    position: relative;
    max-width: 180px;
}

.select-wrapper::after {
    content: "▼";
    font-size: 10px;
    top: calc(41% - 8px);
    border-radius: 50%;
    right: 8px;
    position: absolute;
    color: #2B2B2B;
    width: 18px;
    height: 18px;
    padding: 3px;
    background: #D6DD3D;
    pointer-events: none
}

.generate-ideas-input, .before-generating-ideas-select, .before-generating-ideas-input {
    height: 40px;
    width: 100%;
    border-radius: 20px;
    background: #2B2B2B;
    border: none;
    padding: 0 12px;
    color: white;
}

.before-generating-ideas-select {
    margin-bottom: 10px;
}

.before-generating-ideas-input {
    width: 80px;
    text-align: center;
}

.link-siguiente {
    font-size: 40px;
    color: #fff;
}

@media screen and (max-width: 767px) {
    .inspired-ideas-modal {
        height: auto;
    }
    .inspired-ideas-button {
        display: block;
        margin: auto;
    }
}
