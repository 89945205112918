.thumbnail{
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 1em;
}
.seenapse .thumbnail > p{
  max-width: 8em;
  line-height: 1.1em;
  max-height: 2.4em;
  overflow: hidden;
  padding: .3em 0;
  margin-bottom: .3em;
}
.thumbnail_link{
  width: 7em;
  height: 7em;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  border: #d4e147 0.4em solid;
}
.thumbnail_link.no-border {
  border: none;
}
.landscape .thumbnail{
  width: 8vw;
}
.landscape .thumbnail.column{
  flex-direction: column;
}
.landscape .thumbnail p{
  width: 10vh;
}
.landscape .thumbnail_link{
  width: 7em;
  height: 7em;
}

@media(min-width:768px){
  .seenapse .thumbnail > p{
    font-size: 0.8em;
    width: calc(100vw/8);
  }
}