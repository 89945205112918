:root {
  --primary-yellow: #d4e147;
  --secondary-yellow: #535b19;
}
.seenapse .content-form {
  grid-template-rows: 1fr 1fr 2fr;
  height: 120%;
  width: 70%;
  align-self: baseline;
}

.seenapse .content-form input,
.seenapse .content-form textarea {
  width: 100%;
  background: transparent !important;
  color: #fff;
  border: none;
  text-align: center;
}

.seenapse .content-form input:-internal-autofill-selected {
  background: transparent;
}

.seenapse .content-form h2 {
  color: #fff;
  width: 100%;
}

.seenapse .content-form .menu {
  justify-content: space-around;
}

.seenapse .content-form .menu .cancel {
  color: var(--secondary-yellow);
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 900;
}

.seenapse .content-form .menu .publish {
  color: var(--primary-yellow);
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 900;
}

.landscape .content-form {
  align-self: center;
  grid-template-rows: 1fr 1fr 1fr 2fr 1fr 2fr;
}

.avatar-user-container {
  justify-self: end;
  margin-top: 1em;
  position: relative;
  background: rgba(53, 53, 53, 0.4);
  width: 5vh;
  height: 5vh;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landscape .avatar-user-container {
  width: 5vw;
  height: 5vw;
}

@media (min-aspect-ratio: 1/2) and (min-width: 768px) {
  .seenapse .content-form {
    width: 50%;
    justify-content: center;
  }
}

@media (min-aspect-ratio: 1/1) {
  .landscape .seenapse .content-form {
    height: 47%;
    width: 85%;
  }
}

@media (min-aspect-ratio: 4/3) {
  .landscape .seenapse .content-form {
    height: 55%;
  }
}

@media (min-aspect-ratio: 16/9) {
  .landscape .seenapse .content-form {
    height: 110%;
  }
}

@media (min-width: 420px) {
  .seenapse .content-form {
    width: 50%;
  }
}
@media (min-width: 960px) {
  .landscape .seenapse .content-form {
    justify-content: stretch;
  }
}
