.support-container {
  width: 100%;
  margin-top: 30px;
}

.support-title {
  font-style: normal;
  font-weight: bold;
  color: white;
  font-size: 11px;
  line-height: 12px;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-size: 11px;
}

.support-projects {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.support-project-title {
  display: flex;
  align-items: center;
}

.support-project-title>h4 {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 25px;
  color: #FFFFFF;
  margin-bottom: 0;
  margin-left: 10px;
  cursor: pointer;
}

.support-actions {
  display: flex;
  align-items: center;
}

/* .support-actions>button:first-child {
  margin-right: 16px;
} */
