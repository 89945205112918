:root {
  --primary-yellow: #d4e147;
  --secondary-yellow: #535b19;
}
.seenapse-url-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 13vh;
  height: 13vh;
  position: relative;
}

.landscape .seenapse-url-loader {
  width: 10.7em;
  height: 10.7em;
}

.seenapse-url-loader input {
  color: #fff;
  background: #222;
  padding: 0.5em 1em;
  border-radius: 20px;
  width: 80%;
  position: relative;
  font-size: 1em;
  border: 1px solid #717171;
  width: 90%;
  margin: 0 auto;
  display: block;
}
.seenapse-url-loader p {
  color: #717171;
  font-size: 1em;
  line-height: 1.3em;
  margin: 1em 0;
  width: 90%;
  text-align: center;
}

.seenapse-url-loader .preview {
  width: 3em;
  height: 3em;
  background-size: cover;
  background-position: center center;
  border-radius: 50%;
  cursor: pointer;
}
.seenapse-url-loader .crop-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.9);
}
.seenapse-url-loader .crop-actions-container {
  display: flex;
}
.seenapse-url-loader .actions-container {
  top: 14vh;
  width: 15em;
  position: absolute;
}

.seenapse-url-loader .action {
  color: var(--primary-yellow);
  padding: 0.3em;
  display: block;
  text-transform: uppercase;
  font-weight: 900;
  text-align: center;
}

.seenapse-url-loader .cancel {
  color: var(--secondary-yellow);
  padding: 0.3em;
  display: block;
  text-transform: uppercase;
  font-weight: 900;
  text-align: center;
}

.seenapse-url-loader .action.yellow {
  background: none;
}

.seenapse-url-loader .action.purple {
  background: none;
}

.landscape .seenapse-url-loader .actions-container {
  top: initial;
  left: 12em;
}

.landscape .seenapse-url-loader .action {
  text-align: left;
}
