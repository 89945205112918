.icon-login-mobile {
  display: inline-block;
  cursor: pointer;
  width: 45px;
  height: 45px;
  margin: 10px;
  position: relative;
}

.logo-links-mobile {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.text-item-modal {
  font-family: HelveticaNeue;
  font-size: 20px;
  text-align: inherit;
  color: #fff;
  font-weight: 130;
  letter-spacing: 1px;
  line-height: 1.2;
  padding-bottom: 10px;
}

.link-seenapse {
  color: #d4e245 !important;
  cursor: pointer;
}

.styles_overlay__CLSq-2 {
  background: transparent !important;
  z-index: 1037;
}

.icon-email-mobile {
  background-image: url('../../assests/img/email_on.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
}

.icon-twitter-mobile {
  background-image: url('../../assests/img/twitter_on.png');
  background-repeat: no-repeat;
  cursor: pointer;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
}

.icon-facebook-mobile {
  background-image: url('../../assests/img/facebook_on.png');
  background-repeat: no-repeat;
  cursor: pointer;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
}

.icon-google-mobile {
  background-image: url('../../assests/img/google_on.png');
  background-repeat: no-repeat;
  cursor: pointer;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
}

.facebook-mobile {
  color: #214a8a;
}

.twitter-mobile {
  color: #2091c2;
}

.google-mobile {
  color: #046ccf;
}

.email-mobile {
  color: #8166a8;
}

.no-underline:hover {
  text-decoration: none;
}
