.like{
  cursor: pointer;
  background-size: 100%;
  background-repeat: no-repeat;
  width: 2.0625em;
  height: 2.0625em;
  max-width: 45.5px;
  max-height: 45.5px;
  display: block;
}

.like.on{
  background-image: url('../../assests/img/like_on.svg');
}

.like.off{
  background-image: url('../../assests/img/like_off.svg');
}

.likesNumber{
  color: black;
  font-weight: 500;
  font-size: 1em;
  margin: 0 0 0 0.2rem;
  align-content: center;
}

.share{
  background-size: 100%;
  background-image: url('../../assests/img/share.svg');
  cursor: pointer;
  background-repeat: no-repeat;
  width: 2.0625em;
  height: 2.0625em;
  max-width: 45.5px;
  max-height: 45.5px;
}

.edit{
  background-size: 100%;
  background-image: url('../../assests/img/edit.svg');
  cursor: pointer;
  background-repeat: no-repeat;
  width: 2.0625em;
  height: 2.0625em;
  max-width: 45.5px;
  max-height: 45.5px;
}

.edit span{
  visibility: hidden;
}

.avatar-user{
  border-radius: 50%;
  width: 5vh;
  height: 5vh;
  overflow: hidden;
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
}

.landscape .avatar-user{
  width: 4vw;
  height: 4vw;
}

.container-share {
  background-color: #262626;
  position: absolute;
  border-radius: 50%;
  width: 320px;
  height: 320px;
  z-index: 3000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: calc(50% - 160px);
  left: calc(50% - 160px);
}

.seenapse .container-share p {
  padding: 3%;
  text-align: center;
  color: #FFF;
  font-size: 1em;
  font-weight: 200;
}

.container-share .SocialMediaShareButton{
  color: black;
  text-align: center;
}

.container-share .pointer {
  cursor: pointer;
}
.inspired-seenapse {
  height: 90%;
  width: 90%;
  display: grid;
  font-size: 1.21vh;
}
.menu-idea {
  width: 50%;
}
